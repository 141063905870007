<template>
  <layout-vertical>
    <router-view />
    <template #navbar="{ toggleVerticalMenuActive }">
      <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive"></app-navbar-vertical-layout>
<!--      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />-->
    </template>
    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
//import Navbar from '../components/Navbar.vue'
import AppNavbarVerticalLayout from "@core/layouts/components/app-navbar/AppNavbarVerticalLayout";
export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    //Navbar,
    AppNavbarVerticalLayout
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>